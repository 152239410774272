import React, { useEffect } from "react"
import { Flex, Text, Box } from "@chakra-ui/core"
import firebase from "gatsby-plugin-firebase"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { H1 } from "../components/Typography"
import { useStaticQuery, graphql, Link } from "gatsby"
import AppleSVG from "../components/Layout/apple-app-store.svg"
import Img from "gatsby-image"

export default function AboutUs() {
  useEffect(() => {
    firebase.analytics().setCurrentScreen("specials")
  }, [])
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "google-play-badge.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)
  return (
    <Layout p={[4, 6]}>
      <SEO title="About Us" />
      <H1 textAlign="center">Specials</H1>
      <Text fontSize="xl" mt={20} textAlign="justify">
        To view the latest specials in laudium and surrounding areas
        please download the Laudium Today app.
      </Text>
      <Flex mt={10} direction="column" mb={4}>
      <Flex
        align="center"
        justify="space-between"
        direction={["column", "row"]}
      >
        <Box
          w="260px"
          as={Link}
          to="https://play.google.com/store/apps/details?id=com.laudiumtoday"
          mr={5}
        >
          <Img fluid={data.file.childImageSharp.fluid} />
        </Box>
        <Box
          w="260px"
          as={Link}
          to="https://apps.apple.com/us/app/laudium-today/id1538645970"
          mr={5}
          p={6}
        >
          <img src={AppleSVG} width="100%" />
        </Box>
      </Flex>
    </Flex>
    </Layout>
  )
}
